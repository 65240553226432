<template>
  <div class="container">
    <div class="row border w-100 mx-1 box my-2 pdf">
      <div class="w-25">
        <img
          :src="`https://internal-api.staging-gi.web.id${employee.photoUrl}`"
          alt="profile image"
          height="170"
          class="ml-3 my-2 profil"
        >
      </div>
      <div class="w-50">
        <p class="mt-4 mb-2 name">{{ employee.fullName }}</p>
        <p class="mb-0 employee-data">Status: {{ employee.status }}</p>
        <p class="mb-0 employee-data">Born: {{ moment(employee.birthDate).format('MMMM DD, YYYY') }}</p>
        <p class="mb-0 employee-data">{{ employee.role ? employee.role : null }}</p>
      </div>
      <div class="w-25 pr-4" align="right">
        <img
          src="@/assets/sidebar-logo.png"
          alt="logo"
          width="180"
          class="mt-4 logo"
        >
      </div>
    </div>
    <br><br>
    <div class="border w-100 box pdf p-5">
      <div>
        <label for="education">Education</label>
        <div v-if="employee.education && employee.education.length">
          <p
            v-for="education in employee.education"
            :key="education"
            class="cv"
          >
            - {{ education }}
          </p>
        </div>
        <div v-else class="cv">-</div>
      </div>
      <br>
      <div>
        <label for="employment-history">Employment History</label>
        <div v-if="employee.employmentHistory && employee.employmentHistory.length">
          <p
            v-for="item in employee.employmentHistory"
            :key="item.company"
            class="cv"
          >
            {{ `- ${item.company} (${item.periode}) as ${item.role}` }}
          </p>
        </div>
        <div v-else class="cv">-</div>
      </div>
      <br>
      <div>
        <label for="last-work-and-responsibility">Last Work And Responsibility</label>
        <div v-if="employee.lastWorkResponsibilityEmployee && employee.lastWorkResponsibilityEmployee.length">
          <p
            v-for="item in employee.lastWorkResponsibilityEmployee"
            :key="item.projectName"
            class="cv"
          >
            {{ `- ${stringifiedRole(item.roles)} for ${item.projectName} (${item.periode}) at ${item.company}` }}
          </p>
        </div>
        <div v-else class="cv">-</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import moment from 'moment'

export default {
  name: 'GenerateProfileEmployee',
  props: {
    employee: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      employeeId: JSON.parse(localStorage.getItem('me')).employeeId,
      employees: {
        knowledge: {
          database: [],
          developmentTools: [],
          programmingLanguage: [],
          platform: [],
          framework: [],
          operatingSystem: []
        }
      }
    }
  },
  created() {
    this.fetchEmployeeProfile()
  },
  methods: {
    moment,
    async fetchEmployeeProfile() {
      const { data } = await api.employee.getById(this.employeeId)
      this.employees = data.data
    },
    stringifiedRole(roles) {
      let role = ''
      roles.map((v, i) => {
        if (i === roles.length - 1) role += v
        else role += `${v}, `
      })
      return role
    }
  }
}
</script>

<style scoped>
.box {
  border-left-width: 30px !important;
  border-color: #B0C4DE !important;
}

.name {
  font-size: 28px;
  font-family: 'Times New Roman', Times, serif;
}

.employee-data {
  font-family: 'Times New Roman', Times, serif;
}

label {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}

.cv {
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 800px) {
  .pdf {
    width: 800px;
    overflow-x: scroll;
  }

  .profil {
    height: 100px;
  }

  .logo {
    width: 120px;
  }
}
</style>
